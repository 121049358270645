<template>
  <div class="c-typing-area">
    <div ref="textWrapper" style="display: none;">
      <slot></slot>
    </div>
    <div @click="$refs.input.focus()" class="screen" :class="{
      'is-rtl': rtl,
      'is-touch': isTouchDevice,
      'has-focus': hasFocus
    }" ref="screen">
      <div class="lines">
        <template v-for="(word, widx) in words" :key="widx">
          <div class="word">
            <span v-for="letter in word.letters" :key="letter.char + letter.index" :class="{
              'is-active': currentLetterIndex == letter.index && hasFocus,
              'is-right': isRightLetter(letter),
              'is-wrong': isWrongLetter(letter),
              'space': letter.char == ' ' || letter.char == '\r'
            }" class="letter" v-html="charView(letter, word)">
            </span>
          </div>
          <span v-if="hasLineBreak(word.letters)" :key="widx + 'br'" class="lineBreak"></span>
        </template>
      </div>
    </div>
    <textarea v-model="typeInput" @focus="hasFocus = true" @blur="hasFocus = false" @input="fixTouchInput"
      :class="{ 'is-rtl': rtl }" ref="input" class="input" name="input" rows="1" autofocus="true" autocomplete="off"
      autocorrect="off" autocapitalize="off" spellcheck="false" tabindex="1"></textarea>
  </div>
</template>

<script>
import wrongAudio from '../audio/wrong.ogg';

export default {
  props: {
    rtl: {
      type: Boolean,
      default: false
    },
    /*
     * Typing Mode
     * default: no double key error
     * natural: just normal, no double error protection
     * no-backspace: no wrong letter allowed, prevent "backspace" distraction for beginner
     */
    mode: {
      type: String,
      default: 'default'
    },
    errorSound: {
      type: Boolean,
      default: false
    },
    modelValue: Object,
  },
  data() {
    return {
      text: '',
      audio: null,
      currentLetterIndex: 0,
      currentWordIndex: 0,
      time: { started: 0, finished: 0 },
      states: [],
      timerHandle: 0,
      counter: {
        backspaces: 0,
        wrongLetters: 0,
        correctLetters: 0
      },
      detail: {
        wrongLetters: {},
        keystrokes: []
      },
      isTouchDevice: false,
      typeInput: '',
      hasFocus: false,
    }
  },
  computed: {
    words() {
      var letters = []
      var words = []
      var charIdx = 0

      this.text.split('').forEach(function (char, index) {
        letters.push({
          char: char,
          index: index,
          charIdx: charIdx++
        })
        if (char == ' ' || char == '\r' || char == '\n') {
          if (char == '\r' || char == '\n') {
            letters[letters.length - 1]['char'] = '\r'
          }

          words.push({
            letters: letters
          })
          letters = []
          charIdx = 0
        }
      })

      // push the last word
      words.push({
        letters: letters
      })

      return words
    },
    textSpacesPos() {
      var spacesPos = []
      for (var i = 0; i < this.text.length; ++i) {
        if (
          this.text.substring(i, i + 1) === ' ' ||
          this.text.substring(i, i + 1) === '\r' ||
          this.text.substring(i, i + 1) === '\n'
        ) {
          spacesPos.push(i)
        }
      }
      return spacesPos
    },
    currentLetter() {
      return this.text.charAt(this.currentLetterIndex)
    },
    speed() {
      if (this.states.length < 1) return 0
      var timeElapsed = ((Date.now() - this.time.started) / 1000) | 0
      return Math.floor((this.states.length / 5.1) * (60 / timeElapsed))
    },
    accuracy() {
      if (this.states.length < 1) return 0
      if (this.counter.wrongLetters === 0) return 100
      return Math.floor(this.counter.correctLetters / (this.states.length + this.counter.backspaces) * 100)
    }
  },
  watch: {
    typeInput(newInput, oldInput) {
      // use loop to process multiple character at once
      if (newInput.length > oldInput.length) {
        for (var i = 0; i < newInput.length - oldInput.length; i++) {
          this.doTypeInput(newInput[oldInput.length + i])
        }
      } else {
        for (var i = 0; i < oldInput.length - newInput.length; i++) {
          this.doBackspace()
        }
      }
    }
  },
  methods: {
    charView(letter, word) {
      if (
        this.mode == 'natural' &&
        typeof this.typeInput[letter.index] != 'undefined' &&
        this.isWrongLetter(letter)
      ) {
        // wrong letter typed: show wrong character
        var char = this.typeInput[letter.index]
        if (this.rtl) {
          char = this.arabicJoin(char, this.typeInput[letter.index - 1], this.typeInput[letter.index + 1])
        }
        if (char.trim() == '') char = '&nbsp;'
      } else {
        var char = letter.char == '\r' ? '↵' : letter.char
        if (this.rtl) char = this.arabicLetter(letter.char, letter.charIdx, word.letters)
      }
      return char
    },
    hasLineBreak(letters) {
      return letters[letters.length - 1] !== undefined && letters[letters.length - 1].char == '\r'
    },
    isRightLetter(letter) {
      var typedLetter = this.typeInput[letter.index] == '\n' ?
        '\r' : this.typeInput[letter.index]
      return (letter.char == typedLetter && letter.index <= this.currentLetterIndex)
    },
    isWrongLetter(letter) {
      var typedLetter = this.typeInput[letter.index] == '\n' ?
        '\r' : this.typeInput[letter.index]
      return (letter.char != typedLetter && letter.index < this.currentLetterIndex)
    },
    reset() {
      if (this.timerHandle) {
        clearInterval(this.timerHandle)
        this.timerHandle = 0
      }
      this.currentLetterIndex = 0
      this.currentWordIndex = 0
      this.time = { started: 0, finished: 0 }
      this.states = []
      this.counter = {
        backspaces: 0,
        wrongLetters: 0,
        correctLetters: 0
      }
      this.detail = {
        wrongLetters: {}
      }

      this.updateLines()

      this.$emit('timer-update', 0)
      this.$emit('update:modelValue', {
        accuracy: 0,
        speed: 0,
        details: {},
        time: 0,
        characters: 0
      })
    },
    getStats() {
      return {
        accuracy: this.accuracy,
        speed: this.speed,
        time: this.time.started == 0 ? 0 : parseInt((Date.now() - this.time.started) / 1000),
        details: this.detail.wrongLetters,
        characters: this.counter.correctLetters
      }
    },
    arabicJoin(char, charBefore = '', charAfter = '') {
      if (char == ' ' || char == '\r' || char == '\n') return char

      if (typeof charBefore != 'string') charBefore = ''
      if (typeof charAfter != 'string') charAfter = ''

      var findChars = '،.,:-" \r'.split('')

      var nextConnection = (charAfter.trim() != '')
      if (nextConnection) {
        nextConnection = !(findChars.indexOf(charAfter) >= 0)
      }

      var prevConnection = (charBefore.trim() != '')
      if (prevConnection) {
        // prev letter can't be connected
        findChars += ['ا', 'أ', 'إ', 'آ', 'د', 'ذ', 'ر', 'ز', 'و']
        prevConnection = !(findChars.indexOf(charBefore) >= 0)
      }

      // append or prepend zero width joiner
      char = prevConnection ? '&zwj;' + char : char
      char = nextConnection ? char + '&zwj;' : char

      return char
    },
    arabicLetter(char, char_idx, letters) {
      var midLetter = (char_idx > 0 && char_idx + 1 < letters.length)
      // false if char is the first letter
      var prevConnection = (char_idx > 0)
      // false if char is the last letter
      var nextConnection = (char_idx + 1 < letters.length)

      // next letter can't be connected
      var findChars = '،.,:-" \r'.split('')
      if (char_idx + 1 < letters.length && findChars.indexOf(letters[char_idx + 1].char) >= 0) {
        nextConnection = false
      }

      // prev letter can't be connected
      var findChars = ['-', '"', 'ا', 'أ', 'إ', 'آ', 'د', 'ذ', 'ر', 'ز', 'و']
      if (char_idx > 0 && findChars.indexOf(letters[char_idx - 1].char) >= 0) {
        prevConnection = false
      }

      // append or prepend zero width joiner
      char = prevConnection ? '&zwj;' + char : char
      char = nextConnection ? char + '&zwj;' : char

      return char
    },
    fixTouchInput() {
      if (this.typeInput != this.$refs.input.value) {
        this.typeInput = this.$refs.input.value
      }
    },
    doTypeInput(typedLetter) {
      var letter = this.currentLetter
      if (letter === '\n' && typedLetter.charCodeAt(0) != 10) letter = '\r'

      if (this.time.started === 0) {
        this.time = {
          ...this.time,
          started: Date.now()
        }
        this.$emit('typing-start', this.time.started)

        const self = this
        this.timerHandle = setInterval(() =>
          self.$emit('timer-update', ((Date.now() - self.time.started) / 1000) | 0)
          , 1000)
      }
      this.$emit('typing', typedLetter)
      this.$emit('update:modelValue', this.getStats())

      if (typedLetter === letter) {
        this.states = [...this.states, 1];
        this.currentLetterIndex++
        this.counter = {
          ...this.counter,
          correctLetters: this.counter.correctLetters + 1
        }
      } else {
        this.$emit('typing-error', { letter, typed: typedLetter })

        if (this.errorSound) this.audio.play()

        if (
          // previous letter was wrong
          (this.states[this.states.length - 1] === 0 && this.mode == 'default')
          // or "no backspace" mode
          || this.mode == 'no-backspace'
        ) {
          // doBackspace will be triggered, so we need
          this.states = [...this.states, 1];
          this.typeInput = this.typeInput.substring(0, this.currentLetterIndex++)
        } else {
          if (letter === ' ') letter = '<spasi>'
          if (letter === '\r') letter = '<enter>'

          this.states = [...this.states, 0];
          this.counter = {
            ...this.counter,
            wrongLetters: this.counter.wrongLetters + 1
          }

          if (this.detail.wrongLetters[letter]) {
            this.detail = {
              ...this.detail,
              wrongLetters: {
                ...this.detail.wrongLetters,
                [letter]: this.detail.wrongLetters[letter] + 1
              }
            }
          } else {
            this.detail = {
              ...this.detail,
              wrongLetters: {
                ...this.detail.wrongLetters,
                [letter]: 1
              }
            }
          }

          this.currentLetterIndex++
        }
      }

      if (this.currentLetterIndex > this.textSpacesPos[this.currentWordIndex]) {
        this.currentWordIndex++
        this.updateLines()
      }

      if (this.currentLetterIndex >= this.text.length) {
        this.time = {
          ...this.time,
          finished: Date.now()
        }
        this.$emit('typing-finish', this.time.finished)
      }
    },
    doBackspace() {
      if (this.currentLetterIndex > 0) {
        this.counter = {
          ...this.counter,
          backspaces: this.counter.backspaces + 1
        }
        this.currentLetterIndex--
        this.states = this.states.slice(0, -1)

        if (
          this.currentWordIndex > 0 &&
          this.currentLetterIndex <= this.textSpacesPos[this.currentWordIndex - 1]
        ) {
          this.currentWordIndex--
          this.updateLines()
        }
      }
      this.$emit('update:modelValue', this.getStats())
    },
    updateLines() {
      const screenElm = this.$el
      const linesElm = screenElm.querySelector('.lines')
      const wordsElms = linesElm.querySelectorAll('.word')

      const screenRect = screenElm.getBoundingClientRect()
      const linesRect = linesElm.getBoundingClientRect()
      const wordRect = wordsElms[this.currentWordIndex].getBoundingClientRect()
      const endOfText = linesRect.bottom <= screenRect.bottom

      let yOffset = wordRect.top - linesRect.top
      let doScroll = true

      // don't do scroll directly, allow user to see wrongs on the upper line
      if (
        (!this.rtl && wordRect.left - linesRect.left < linesRect.width / 4) ||
        (this.rtl && linesRect.right - wordRect.right < linesRect.width / 4)
      )
        doScroll = false

      // short line with enter
      if (!doScroll && wordRect.top > screenRect.top + (wordRect.height * 2)) {
        doScroll = true
        yOffset -= wordRect.height
      }

      // backspacing / scrolling up, do immediately
      if (!doScroll && yOffset < parseInt(linesElm.style.transform.match(/\d+/))) {
        doScroll = true
      }
      else if (doScroll && endOfText) {
        doScroll = false
      }

      if (doScroll) {
        linesElm.style.transform = 'translateY(-' + yOffset + 'px)'
      }
    }
  },
  mounted() {
    var self = this

    this.text = this.$refs.textWrapper.innerHTML.trim()

    this.audio = new Audio(wrongAudio)

    document.addEventListener("focus", () => self.$refs.input.focus());

    document.addEventListener('touchstart', () => {
      self.isTouchDevice = true

      // For some reason, the focus is immediately lost
      // unless there is a delay on setting the focus
      setTimeout(() => {
        self.$refs.input.focus()
        self.$refs.screen.addEventListener(
          'touchstart',
          () => setTimeout(() => self.$refs.input.focus(), 200)
        )
      }, 200)
    }, { once: true })

    self.$refs.input.focus()
  },
  beforeDestroy() {
    this.$emit('update:modelValue', this.getStats())
  }
}
</script>

<style scoped>
.screen {
  font-family: Fira Mono, Fira Code, monospace;
  font-size: 30px;
  line-height: 45px;
  max-height: calc((45px + 10px + 3px) * 5 - 1px);
  overflow: hidden;
  width: 100%;
}

.screen.is-rtl {
  direction: rtl;
  font-family: 'Kawkab Mono', 'Courier New', Courier, monospace;
}

.lines {
  align-items: center;
  background-image: linear-gradient(180deg, transparent 57px, rgba(0, 0, 0, 0.15) 1px);
  background-size: 58px 58px;
  background-repeat: repeat;
  display: flex;
  flex-wrap: wrap;
  transition: transform 0.2s ease;
  width: 100%;
  will-change: transform;
}

.lines>>>.lineBreak {
  width: 100%;
}

.lines>>>.word {
  display: flex;
}

.lines>>>.letter {
  border-bottom: 3px solid transparent;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.75);
  display: inline-block;
  margin: 5px 2px 5px 0;
  text-align: center;
  width: 20px;
}

.lines>>>.letter.space {
  color: rgba(0, 0, 0, 0.2);
}

@keyframes blinker {
  50% {
    border-bottom-color: transparent;
  }
}

.lines>>>.letter.is-active {
  border-bottom-color: #45aaf2;
  border-radius: 0;
  color: #45aaf2;
  animation: blinker 1s steps(1) infinite;
}

.lines>>>.letter.is-right {
  /* background-color: #e6fbf0; */
  color: rgba(0, 0, 0, 0.2);
}

.lines>>>.letter.is-wrong {
  background-color: #fee1e3;
  color: #fc5c65;
}

.input {
  width: 100%;
  /* with 0 height, strange happen in chrome android */
  height: 1px;
  color: transparent;
  padding: 0;
  opacity: 0;
  border: none;
}

.input:focus {
  outline: none;
}

.input.is-rtl {
  direction: rtl;
}

@media only screen and (max-width: 480px) {
  .screen {
    font-size: 20px;
    line-height: 30px;
  }

  .screen.is-touch {
    max-height: calc((30px + 10px + 3px) * 3 - 1px);
  }

  .lines {
    background-image: linear-gradient(180deg, transparent 42px, rgba(0, 0, 0, 0.15) 1px);
    background-size: 43px 43px;
    background-repeat: repeat;
  }

  .lines>>>.letter {
    width: 13px;
  }
}
</style>