<template>
  <div class="c-typing-test">
    <typing-area :rtl="rtl" :mode="mode" error-sound @typing-start="eventTypingStart" @typing-finish="eventTypingFinish"
      v-model="stats" v-if="!gameover" class="u-main-box">
      <slot></slot>
    </typing-area>
    <div class="gameover u-main-box" v-if="gameover">
      <p>Kecepatan Anda {{ stats.speed }} KPM dengan akurasi {{ stats.accuracy }}%!</p>
      <table v-if="Object.keys(stats.details).length !== 0">
        <thead>
          <tr>
            <th width="200">Huruf</th>
            <th>Salah</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="w in wrongsDetailsSorted" :key="w.letter">
            <td>{{ w.letter }}</td>
            <td>{{ w.count }}</td>
          </tr>
        </tbody>
      </table>
      <p><button @click="restartGame">Coba Lagi</button></p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rtl: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'default'
    },
    duration: {
      type: Number,
      default: 60
    }
  },
  data() {
    return {
      timerHandle: 0,
      stats: {
        accuracy: 0,
        speed: 0,
        details: {}
      },
      remainingTime: 0,
      started: 0,
      gameover: false
    }
  },
  computed: {
    wrongsDetailsSorted: function () {
      var sorted = []
      for (var key in this.stats.details) {
        if (this.stats.details.hasOwnProperty(key)) {
          sorted.push({
            letter: key,
            count: this.stats.details[key]
          })
        }
      }
      sorted.sort((a, b) => b.count - a.count)
      return sorted
    }
  },
  methods: {
    eventTypingStart(started) {
      this.started = started
      this.startGame()
    },
    eventTypingFinish() {
      this.finishGame()
    },
    startGame() {
      const self = this
      this.timerHandle = setInterval(function () {
        self.remainingTime = self.duration - (((Date.now() - self.started) / 1000) | 0)
        self.$emit('timer-update', self.remainingTime)

        if (self.remainingTime <= 0) {
          self.finishGame()
        }
      }, 1000)
    },
    finishGame() {
      clearInterval(this.timerHandle)
      this.timerHandle = 0
      this.gameover = true
      this.$emit('game-over')
    },
    restartGame() {
      this.started = 0
      this.stats = {
        accuracy: 0,
        speed: 0,
        details: {}
      }
      this.remainingTime = 0
      this.gameover = false

      this.$emit('timer-update', this.duration)
    },
    handleKeyDown(e) {
      if (e.keyCode === 27) { // escape key
        this.gameover = true

        const self = this
        this.$nextTick(function () {
          clearInterval(self.timerHandle)
          self.timerHandle = 0

          self.restartGame()
        })
      }
    }
  },
  mounted() {
    this.$emit('timer-update', this.duration)

    window.addEventListener('keydown', this.handleKeyDown)
  },
  beforeDestroy() {
    clearInterval(this.timerHandle)

    window.removeEventListener('keydown', this.handleKeyDown)
  }
}
</script>

<style scoped>
.gameover {
  font-size: 20px;
  text-align: center;
}

table {
  border-collapse: collapse;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  margin: 0 auto;
  text-align: center;
}

th,
td {
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 5px 10px;
}

button {
  background-color: #fed330;
  border: 2px solid #f7b731;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  font-size: 20px;
  padding: 5px 10px;
}
</style>
