import { createApp } from 'vue/dist/vue.esm-bundler';
import './main.css';
import TypingTimer from './components/TypingTimer.vue';
import TypingArea from './components/TypingArea.vue';
import TypingTest from './components/TypingTest.vue';

const app = createApp({
  data: () => ({
    typing: {
      model: {
        time: 0,
      },
    },
  }),
});

app.config.compilerOptions.whitespace = 'preserve';

app.component('typing-timer', TypingTimer);
app.component('typing-area', TypingArea);
app.component('typing-test', TypingTest);

app.mount('#belajarketik-app');
