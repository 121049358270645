<template>
  <span class="c-typing-timer">
    {{ timeDisplay }}
  </span>
</template>

<script>
export default {
  props: {
    time: {
      type: Number,
      default: 0
    }
  },
  computed: {
    timeDisplay() {
      var mins = parseInt(this.time / 60, 10)
      var secs = parseInt(this.time % 60, 10)

      secs = secs < 0 ? 0 : secs // prevent negative number
      secs = secs < 10 ? '0' + secs : secs

      return mins + ':' + secs
    }
  }
}
</script>
